  /*? React Styling */

@font-face {
	font-family: GT Walsheim Pro;
	src        : local("GT Walsheim Pro Regular"), local("GTWalsheimProRegular"),
		url(./assets/gt-walsheim/GTWalsheimProRegular.woff2) format("woff2"),
		url(./assets/gt-walsheim/GTWalsheimProRegular.woff) format("woff"),
		url(./assets/gt-walsheim/GTWalsheimProRegular.ttf) format("truetype");
	font-weight: 400;
	font-style : normal;
}

@font-face {
	font-family: GT Walsheim Pro;
	src        : local("GT Walsheim Pro Bold"), local("GTWalsheimProBold"),
		url(./assets/gt-walsheim/GTWalsheimProBold.woff2) format("woff2"),
		url(./assets/gt-walsheim/GTWalsheimProBold.woff) format("woff"),
		url(./assets/gt-walsheim/GTWalsheimProBold.ttf) format("truetype");
	font-weight: 700;
	font-style : normal;
}

.App > * {
	font-family: GT Walsheim Pro, "Menlo";
	text-align : center;
}

.App-logo {
	height        : 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-main {
	background-color: #282c34;
	min-height      : 100vh;
	display         : flex;
	flex-direction  : column;
	align-items     : center;
	justify-content : center;
	font-size       : calc(10px + 2vmin);
	color           : white;
}

.App-link {
	color: #61dafb;
}

  /** Animations */

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

  /*& Custom Styling */

.gradient {
	background: rgb(162, 125, 255);
	background: linear-gradient(
		225deg,
		rgba(162, 125, 255, 1) 0%,
		rgba(117, 138, 255, 1) 100%
	);
	background-clip        : text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.radius {
	border-radius: calc(5px + 0.2rem);
}

.border {
	border: 3px solid;
}

.mono {
	font-family: "Ubuntu Mono", "Menlo", "Courier New", Courier, monospace;
	font-weight: 500;
}

.no-decor {
	text-decoration: none;
}

glow {
	opacity: 80%;
}

glow:hover {
	opacity: 100%;
}
